import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEOHeader from "../components/seo-header"
import { kebabCase } from "lodash"
import scrollTo from "gatsby-plugin-smoothscroll"
const BlogPostTemplate = props => {
  const post = props.data.markdownRemark
  const [scrollState, setscrollState] = useState("top")
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setscrollState("top")
    } else if (window.scrollY > 1600) {
      setscrollState("onmoving")
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll)
  }
  return (
    <Layout location={props.location} title="Test" type="blogPage">
      <SEOHeader
        title={post.frontmatter.title + " -  Silvergray Photography"}
        description={
          post.frontmatter.title + " - Silvergray Photography by Pranaav BR"
        }
        socialLocalURL={post.frontmatter.featuredImage.publicURL}
        pinterest="true"
        socialURL=""
      ></SEOHeader>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 blog">
            <div className={"scrollDown " + scrollState}>
              Scroll Down <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </div>
            <div
              className="blogContent"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p>
              <br></br>
            </p>

            <p className="text-center">
              <a onClick={() => scrollTo("#top")}>
                Back to Top <i class="fa fa-arrow-up" aria-hidden="true"></i>
              </a>{" "}
            </p>
            <p>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      frontmatter {
        title
        order
        category
        featuredImage {
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
